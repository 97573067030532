import React from "react";

import GridSenseScreen from "./gridsense/GridSenseScreen";

const DevelopLinksScreen = () => {

    return (
        <div className="w-100 h-100 text-center text-white moving-content">
            <div className="grid-container">
                <div className="grid-item grid-item-long grid-item-wide">
                    <GridSenseScreen customWidthClass={"w-50"} energyFlowScaling={1.5} />
                </div>
                <div className="grid-item grid-item-long grid-item-narrow">
                    <iframe
                        src="https://uptime1.zsnode.com/status/default"
                        title="Uptime Kuma"
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                    />
                </div>
            </div>
        </div>
    );
}

export default DevelopLinksScreen;
