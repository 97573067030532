import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";
import PlanningColumn from "../components/planning/PlanningColumn";
import AbsenceColumn from "../components/planning/AbsenceColumn";
import ClockColumn from "../components/planning/ClockColumn";
import BirthdayWidget from "../components/planning/BirthdayWidget";

class PlanningScreen extends React.PureComponent {
    state = {
        planningItems: null,
        planningComments: null,
        absenceItems: null,
        images: null,
        error: null,

        previousDay: null,
        nextDay: null,
        secondNextDay: null
    }

    componentDidMount() {
        this.refresh();
        this.refreshInterval = setInterval(() => {
            this.refresh();
        }, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    refresh() {
        this.getPlanningItems();
        this.getPlanningComments();
        this.getAbsenceItems();
        this.getImages();
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getRequestHeaders() {
        const token = this.getToken();
        return token ? { Token: token } : {};
    }

    getAbsenceItems() {
        this.setState({ error: null });
        let startDate = this.momentStartDate().format("YYYY-MM-DD");
        let endDate = this.momentEndDate().format("YYYY-MM-DD");
        axios.post("/getAbsenceItems", { startDate, endDate }, { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    let absenceItems = response.data.data.sort((absenceItem1, absenceItem2) => {
                        return absenceItem1.user.name.localeCompare(absenceItem2.user.name);
                    })
                    this.setState({ absenceItems });
                } else {
                    this.setState({ absenceItems: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ absenceItems: null, error: this.getErrorMessage(null) });
            });
    }

    getPlanningItems() {
        this.setState({ error: null });
        let startDate = this.momentStartDate().format("YYYY-MM-DD");
        let endDate = this.momentEndDate().format("YYYY-MM-DD");
        axios.post("/getPlanningItems", {
            startDate,
            endDate,
            withAvailability: 1,
        }, { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    const planningItems = response.data.data;
                    this.setState({
                        planningItems,
                        previousDay: this.getPreviousDay(planningItems),
                        nextDay: this.getNextDay(planningItems),
                        secondNextDay: this.getSecondNextDay(planningItems)
                    });
                } else {
                    this.setState({ planningItems: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: this.getErrorMessage(null) });
            });
    }

    getPlanningComments() {
        this.setState({ error: null });
        let startDate = this.momentStartDate().format("YYYY-MM-DD");
        let endDate = this.momentEndDate().format("YYYY-MM-DD");
        axios.post("/getPlanningComments", { startDate, endDate }, { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ planningComments: response.data.data });
                } else {
                    this.setState({ planningComments: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: this.getErrorMessage(null) });
            });
    }

    getImages() {
        this.setState({ error: null });
        axios.post("/getImages", { amount: 100, offset: 0 }, { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ images: response.data.data });
                } else {
                    this.setState({ images: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ images: null, error: this.getErrorMessage(null) });
            });
    }

    momentStartDate() {
        return moment().subtract(7, "days");
    }

    momentEndDate() {
        return moment().add(7, "days");
    }

    getPreviousDay(allPlanningItems) {
        let day = moment().subtract(1, "days");
        for(let i = 0; i < 3; i++) {
            const planningItems = this.getPlanningItemsForDate(day, allPlanningItems);
            if(planningItems.length > 0) {
                return day;
            }
            day = day.subtract(1, "days");
        }
        return moment().subtract(1, "days");
    }

    getNextDay(allPlanningItems) {
        let day = moment().add(1, "days");
        for(let i = 0; i < 3; i++) {
            const planningItems = this.getPlanningItemsForDate(day, allPlanningItems);
            if(planningItems.length > 0) {
                return day;
            }
            day = day.add(1, "days");
        }
        return moment().add(1, "days");
    }

    getSecondNextDay(allPlanningItems) {
        let day = this.getNextDay(allPlanningItems).add(1, "days");
        for(let i = 0; i < 3; i++) {
            const planningItems = this.getPlanningItemsForDate(day, allPlanningItems);
            if(planningItems.length > 0) {
                return day;
            }
            day = day.add(1, "days");
        }
        return this.getNextDay(allPlanningItems).add(1, "days");
    }

    getAbsenceItemsForDate(momentDate) {
        if(!this.state.absenceItems || !momentDate) {
            return null;
        }
        return this.state.absenceItems.filter((absenceItem) => {
            return moment(absenceItem.startDate) < momentDate && moment(absenceItem.endDate).endOf("day") > momentDate;
        })
    }

    getPlanningItemsForDate(momentDate, planningItems = this.state.planningItems) {
        if(!planningItems || !momentDate) {
            return null;
        }
        return planningItems.filter((planningItem) => {
            return planningItem.date === momentDate.format("YYYY-MM-DD");
        })
    }

    getPlanningCommentForDate(momentDate, planningComments = this.state.planningComments) {
        if(!planningComments || !momentDate) {
            return null;
        }
        let results = planningComments.filter((planningComment) => {
            return planningComment.date === momentDate.format("YYYY-MM-DD");
        });
        return results.length > 0 ? results[0] : null;
    }

    getImagesForDate(momentDate, images = this.state.images) {
        if(!images || !momentDate) {
            return null;
        }
        return images.filter((image) => {
            return moment(image.date).format("YYYY-MM-DD") === momentDate.format("YYYY-MM-DD");
        })
    }

    getDayName(momentDate) {
        if(!momentDate) {
            return "";
        }
        const day = momentDate.format("dddd");
        return day.charAt(0).toUpperCase() + day.slice(1);
    }

    render() {
        const {
            previousDay,
            nextDay,
            secondNextDay,
            error
        } = this.state;

        const linear = true;
        const duration = 30000;

        if(error) {
            return (
                <div className="container d-flex align-items-center">
                    <Alert variant="danger" className="w-100 text-center" style={{ fontSize: "1.5rem" }}>
                        { error }
                    </Alert>
                </div>
            )
        }
        const planningItemsToday = this.getPlanningItemsForDate(moment());
        const imagesToday = this.getImagesForDate(moment());
        return (
            <div className="d-flex flex-column h-100 p-3 moving-content">
                <div className="row mb-3" style={{ maxHeight: "160px" }}>

                    <AbsenceColumn
                        absenceItems={ this.getAbsenceItemsForDate(previousDay) }
                    />
                    <AbsenceColumn
                        absenceItems={ this.getAbsenceItemsForDate(planningItemsToday ? moment() : null) }
                    />

                    <div className="col d-flex flex-column h-100">
                        <ClockColumn/>
                        <BirthdayWidget/>
                    </div>

                    <AbsenceColumn
                        absenceItems={ this.getAbsenceItemsForDate(nextDay) }
                    />
                    <AbsenceColumn
                        absenceItems={ this.getAbsenceItemsForDate(secondNextDay) }
                    />

                </div>
                <div className="row" style={{ flex: "1 1 0", minHeight: 0 }}>

                    <PlanningColumn
                        title={ this.getDayName(previousDay) }
                        planningItems={ this.getPlanningItemsForDate(previousDay) }
                        planningComment={ this.getPlanningCommentForDate(previousDay) }
                        images={ this.getImagesForDate(previousDay) }
                        linear={ linear }
                        duration={ duration }
                        startInstantly
                    />
                    <PlanningColumn
                        title="Installaties vandaag"
                        planningItems={ !planningItemsToday ? null : planningItemsToday.filter((planningItem) => {
                            return parseInt(planningItem.type.id) !== 2;
                        }) }
                        planningComment={ this.getPlanningCommentForDate(moment()) }
                        images={ imagesToday }
                        linear={ linear }
                        duration={ duration }
                        startInstantly
                    />
                    <PlanningColumn
                        title="Storingen vandaag"
                        planningItems={ !planningItemsToday ? null : planningItemsToday.filter((planningItem) => {
                            return parseInt(planningItem.type.id) === 2;
                        }) }
                        images={ imagesToday }
                        linear={ linear }
                        duration={ duration }
                        startInstantly
                    />
                    <PlanningColumn
                        title={ this.getDayName(nextDay) }
                        planningItems={ this.getPlanningItemsForDate(nextDay) }
                        planningComment={ this.getPlanningCommentForDate(nextDay) }
                        linear={ linear }
                        duration={ duration }
                        startInstantly
                    />
                    <PlanningColumn
                        title={ this.getDayName(secondNextDay) }
                        planningItems={ this.getPlanningItemsForDate(secondNextDay) }
                        planningComment={ this.getPlanningCommentForDate(secondNextDay) }
                        linear={ linear }
                        duration={ duration }
                        startInstantly
                    />

                </div>
            </div>
        );
    }
}

export default PlanningScreen;
