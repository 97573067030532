import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import moment from "moment";
import Snowfall from "react-snowfall";

function ChristmasSnow() {
    const [show, setShow] = useState(false);

    const updateShow = useCallback(() => {
        const start = moment(moment().format("Y") + "-12-06");
        const end = moment(moment().format("Y") + "-12-26");
        const now = moment().startOf("day");

        const christmas = start <= now && end >= now;
        const debug = window.location.search.includes("snow");

        setShow(christmas || debug);
    }, []);

    useEffect(() => {
        const interval = setInterval(updateShow, 60 * 60 * 1000);
        updateShow();
        return () => clearInterval(interval);
    }, [updateShow]);

    if(!show) {
        return null;
    }

    return (
        <Snowfall
            style={{ zIndex: 10000 }}
        />
    )
}

export default React.memo(ChristmasSnow);
