const detectScaling = () => {
    const screen = window.screen;
    const scalingRatio = screen.width / window.innerWidth;
    const content = document.querySelector('.moving-content');

    if (content !== null) {
        if (scalingRatio >= 1.9 && scalingRatio <= 2.1) {
            // 200% scaling
            content.classList.add('moving-content-high-scaling');
            content.classList.remove('moving-content-standard');
        } else {
            content.classList.add('moving-content-standard');
            content.classList.remove('moving-content-high-scaling');
        }
    }
};

export default detectScaling;
