import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";

import Loading from "../components/Loading";
import VerticalAutoScroll from "../components/VerticalAutoScroll";
import ImageGallary from "../components/ImageGallary";
import getScrollingImagesScrollDuration from "../components/getScrollingImagesScrollDuration";

class ImagesScrollingScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            total: null,
            projected: null,
            topLeaderboardUser: null,

            error: null,
            duration: getScrollingImagesScrollDuration()
        }
    }

    componentDidMount() {
        this.refresh();
        this.refreshInterval = setInterval(() => {
            this.refresh();
        }, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    refresh() {
        this.getImages();
        this.getLeaderboard();
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getStartDate(leaderboard = false) {
        // if(!leaderboard) {
        //     if(moment().isoWeekday() === 1) {
        //         return moment().subtract(3, "days").format("YYYY-MM-DD");
        //     }
        // }
        return moment().startOf("week").format("YYYY-MM-DD");
    }

    getEndDate(leaderboard = false) {
        if(leaderboard) {
            return moment().format("YYYY-MM-DD");
        } else {
            return moment().add(1, "day").format("YYYY-MM-DD");
        }
    }

    getImages() {
        this.setState({ error: null });
        const token = this.getToken();
        const headers = token ? { Token: token } : {};
        const filters = [
            "outage",
            // "outageComment",
            "installation",
            "installationComment",
        ].join(",");

        const startDate = this.getStartDate();
        const endDate = this.getEndDate();

        axios.post("/getImages", { startDate, endDate, filters }, { headers })
            .then((response) => {
                if(response.data.valid) {
                    const images = response.data.data;
                    const duration = Math.ceil(images.length / 6) * 3500;
                    this.setState({ images, duration });
                    if(this.props.setDuration) {
                        this.props.setDuration(duration + 2000);
                    }
                } else {
                    this.setState({ images: null, error: this.getErrorMessage(response.data.error) });
                    if(this.props.setDuration) {
                        this.props.setDuration(2000);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ images: null, error: this.getErrorMessage(null) });
                if(this.props.setDuration) {
                    this.props.setDuration(2000);
                }
            });
    }

    getLeaderboard() {
        this.setState({ error: null });
        const token = this.getToken();
        const headers = token ? { Token: token } : {};

        const startDate = this.getStartDate(true);
        const endDate = this.getEndDate(true);

        axios.post("/getImageLeaderboard", { startDate, endDate }, { headers })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({
                        total: response.data.mechanicTotal,
                        projected: response.data.mechanicProjected,
                        topLeaderboardUser: response.data.mechanic.length > 0 ? response.data.mechanic[0] : null
                    });
                } else {
                    this.setState({ error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: this.getErrorMessage(null) });
            });
    }

    render() {
        if(this.state.error) {
            return (
                <div className="container d-flex align-items-center">
                    <Alert variant="danger" className="w-100 text-center" style={{ fontSize: "1.5rem" }}>
                        { this.state.error }
                    </Alert>
                </div>
            )
        }
        if(!this.state.images) {
            return (
                <div className="container-fluid h-100 d-flex align-items-center">
                    <div className="w-100">
                        <Loading white/>
                    </div>
                </div>
            )
        }
        const { total, projected, topLeaderboardUser } = this.state;
        const banner = (
            <div className="images-scrolling-banner">
                <div className="w-100 text-center py-3 moving-content">
                    { topLeaderboardUser !== null && (
                        <div className="h-100 pt-1 px-4 text-left" style={{ position: "absolute", top: 5, left: 0 }}>
                            <div className="d-flex align-items-center">
                                <div>
                                    <h2 className="mb-0 mr-4" style={{ color: "#FF5000" }}>
                                        <i className="fas fa-award"/>
                                    </h2>
                                </div>
                                <div>
                                    <h4 className="mb-0">
                                        Meeste monteur foto's:
                                    </h4>
                                    <h2 className="mb-0">
                                        { topLeaderboardUser.name }
                                        <small className="ml-2">
                                            ({ topLeaderboardUser.numImages })
                                        </small>
                                    </h2>
                                </div>
                            </div>

                        </div>
                    )}
                    <h1>Foto's deze week</h1>
                    { total !== null && projected !== null && (
                        <div className="h-100 pt-1 px-4 text-right" style={{ position: "absolute", top: 5, right: 0 }}>
                            <h2 className="mb-0">
                                { total } / { projected }
                            </h2>
                            { total === 0 ? (
                                <h4 className="mt-0">{ projected } te maken</h4>
                            ) : projected - total > 0 ? (
                                <h4 className="mt-0" style={{ color: "rgba(255, 100, 100, 1)"}}>{ projected - total } te weinig foto's gemaakt</h4>
                            ) : (
                                <h4 className="mt-0 text-success">Goed bezig!</h4>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
        if(this.state.images.length === 0) {
            return (
                <div className="h-100 d-flex align-items-center flex-column text-white">
                    { banner }
                    <div className="w-100 flex-grow-1 d-flex align-items-center">
                        <div className="w-100 text-center">
                            <h1>Nog geen foto's gemaakt deze week.</h1>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="d-flex flex-column h-100">
                { banner }
                <VerticalAutoScroll
                    linear
                    className="white-scrollbar wide-scrollbar"
                    startInstantly
                    duration={ this.state.duration }
                    controlled={ this.props.inCarousel }
                    showing={ this.props.showing }
                >
                    <ImageGallary images={ this.state.images }/>
                </VerticalAutoScroll>
            </div>
        )
    }

}

export default ImagesScrollingScreen;
