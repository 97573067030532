import React from "react";

const DevelopRechtsScreen = () => {
    return (
        <div className="w-100 h-100 text-center text-white moving-content">
            <div className="grid-container">
                <div className="grid-item">
                    <iframe
                        src="https://sentry1.zsnode.com/"
                        title="Uptime Kuma"
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                    />
                </div>
                <div className="grid-item">
                    <iframe
                        src="https://uptime1.zsnode.com/status/default"
                        title="Uptime Kuma"
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                    />
                </div>
                <div className="grid-item">
                    <iframe
                        src="https://uptime1.zsnode.com/status/default"
                        title="Uptime Kuma"
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                    />
                </div>
                <div className="grid-item">
                    <iframe
                        src="https://uptime1.zsnode.com/status/default"
                        title="Uptime Kuma"
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                    />
                </div>
            </div>
        </div>
    );
}

export default DevelopRechtsScreen;
